import { AsyncPipe } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { NgProgressComponent, NgProgressModule } from 'ngx-progressbar';
import { combineLatest, map } from 'rxjs';

import { BreakpointService, ProgressService } from './core/services';
import { DashboardLayoutComponent } from './layout';
import { MainLayoutModule } from './layout/main-layout/main-layout.module';

@Component({
  selector: 'cool-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NgProgressModule,
    AsyncPipe,
    MainLayoutModule,
    DashboardLayoutComponent,
  ],
  template: `
    <ng-progress
      [ariaLabel]="'progress'"
      [color]="'#F74141'"
      [spinner]="false"
      [thick]="true"
    ></ng-progress>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements AfterViewInit {
  @ViewChild(NgProgressComponent) progress: NgProgressComponent;

  constructor(
    private progressService: ProgressService,
    private destroyRef: DestroyRef,
    private breakpointService: BreakpointService
  ) {}

  ngAfterViewInit(): void {
    this.initProgress();
  }

  private initProgress(): void {
    combineLatest([
      this.progressService.status$,
      this.breakpointService.observe$(),
    ])
      .pipe(
        map(([status]) => {
          if (status) {
            this.progress.start();
          } else {
            this.progress.complete();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
