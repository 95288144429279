import { inject } from '@angular/core';
import { isBrowser } from '@jsverse/transloco';
import { delay, EMPTY, Observable, tap } from 'rxjs';


import { SplashService, StorageService, ThemeService } from '../services';
import { ThemeType } from '../../typings';
import { THEME } from '../../constants';

export function themeFactory(
  themeService: ThemeService,
  storageService: StorageService,
): () => Observable<ThemeType> {
  if (isBrowser()) {
    const storageTheme = storageService.theme ?? THEME.default;
    const splashService = inject(SplashService);

    return (): Observable<ThemeType> =>
      themeService.loadTheme$(storageTheme, false).pipe(
        delay(300),
        tap(() => splashService.hideSplash()),
      );
  }

  return (): Observable<never> => EMPTY;
}
