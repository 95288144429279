import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';


import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainLayoutComponent } from './main-layout.component';
import { LogoComponent, SvgIconComponent } from '../../shared/components';

@NgModule({
  declarations: [MainLayoutComponent, MainHeaderComponent, MainFooterComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    LogoComponent,
    NzButtonModule,
    SvgIconComponent,
    NzDropDownModule,
    NzDrawerModule,
    NzDividerModule,
    TranslocoModule
  ],
})
export class MainLayoutModule {}
