<div class="main-layout">
    <header class="header">
        <cool-main-header 
            [currentTheme]="currentTheme()"
            [currentLang]="currentLang()"
            [isMobile]="isMobile()"
            (themeChange)="themeChange$.next($event)"
            (langChange)="langChange$.next($event)">
        </cool-main-header>
    </header>
    <main class="main">
        <router-outlet></router-outlet>
    </main>
    <footer class="footer">
        <cool-main-footer></cool-main-footer>
    </footer>

    <button 
        nz-button
        nz-dropdown 
        [nzTrigger]="'click'"
        ngSkipHydration
        [nzDropdownMenu]="themeMenu"
        class="theme"
    >
        <cool-svg-icon [name]="'c_theme'"></cool-svg-icon>
    </button>
</div>

<nz-dropdown-menu #themeMenu="nzDropdownMenu">
    <ul 
        nz-menu 
        nzSelectable
    >
        @for (item of themes(); track item.theme) {
            <li 
                nz-menu-item
                [nzSelected]="item.theme === currentTheme()"
                (click)="themeChange$.next(item.theme)"
            >
                {{item.name | transloco}}
            </li>
        }
    </ul>
</nz-dropdown-menu>