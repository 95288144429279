import { isBrowser } from '@jsverse/transloco';
import { Observable, of } from 'rxjs';

import { LanguageService, StorageService } from '../services';
import { LANGUAGE } from '../../constants';

export function langFactory(
  langService: LanguageService,
  storageService: StorageService,
): () => Observable<string> {
  if (isBrowser()) {
    const lang = storageService.lang ?? LANGUAGE.en;

    return (): Observable<string> => langService.onChangeLang$(lang, false);
  }

  return (): Observable<LANGUAGE> => of(LANGUAGE.en);
}
