import { Routes } from '@angular/router';

import { LANGUAGE } from './constants';
import { MainLayoutComponent } from './layout';

const availableLangs = ['', 'ru'];

export const languageRoutes: Routes = availableLangs.map((lang) => ({
  path: lang,
  component: MainLayoutComponent,
  children: [
    {
      path: '',
      loadChildren: () =>
        import('./modules/main/main.module').then((m) => m.MainModule),
    },
  ],
}));

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LANGUAGE.en,
  },
  ...languageRoutes,
];
