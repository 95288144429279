import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, Subject, switchMap } from 'rxjs';
import { ThemeItem } from '../../typings';
import { LANGUAGE, THEME } from '../../constants';
import { BreakpointService, LanguageService, ThemeService } from '../../core/services';


@Component({
  selector: 'cool-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit {
  currentLang = toSignal(this.languageService.currentLang$);
  isMobile = toSignal(this.breakpointService.isMobile$);
  themes = signal<ThemeItem[]>(this.initTheme());
  currentTheme = toSignal<THEME>(
    this.themeService.currentTheme$.pipe(
      filter((theme) => !!theme),
      map(({ current }) => current),
    ),
  );

  langChange$ = new Subject<LANGUAGE>();
  themeChange$ = new Subject<THEME>();

  constructor(
    private languageService: LanguageService,
    private destroyRef: DestroyRef,
    private breakpointService: BreakpointService,
    private themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    this.onChangeLang();
    this.onChangeTheme();
  }

  private onChangeLang(): void {
    this.langChange$
      .pipe(
        switchMap((lang) => this.languageService.onChangeLang$(lang)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private onChangeTheme(): void {
    this.themeChange$
      .pipe(
        switchMap((theme) => this.themeService.loadTheme$(theme)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private initTheme(): ThemeItem[] {
    return [
      {
        theme: THEME.default,
        name: 'theme.default',
      },
      {
        theme: THEME.dark,
        name: 'theme.dark',
      },
    ];
  }
}
