<ng-container *transloco="let t">
    <div class="main-header">
        <div class="container">
            <cool-logo></cool-logo>
    
            <div class="actions">
                <button 
                    nz-button 
                    class="hide"
                    (click)="blockClicked.emit()"
                >
                    {{ t('blog') }}
                </button>

                <button 
                    nz-button 
                    class="hide"
                    (click)="contactClicked.emit()"
                >
                    {{ t('contact') }}
                </button>
                
                <button 
                    nz-button 
                    nzType="primary"
                    ngSkipHydration
                    class="action hide"
                    (click)="onLangChange()"
                >
                    <span>{{currentLang()}}</span>

                    <cool-svg-icon 
                        [name]="'c_global'">
                    </cool-svg-icon>
                </button>
                
                <button 
                    nz-button 
                    ngSkipHydration
                    class="action burger"
                    [ngClass]="{ active: burgerState() }"
                    (click)="toggleBurger()"
                >
                    <cool-svg-icon 
                        [name]="burgerIcon()">
                    </cool-svg-icon>
                </button>
            </div>
        </div>
    </div>

    @if(isMobile()){
        <nz-drawer
            (nzOnClose)="toggleBurger()"
            [nzClosable]="false"
            [(nzVisible)]="burgerState"
            nzPlacement="top"
            [nzTitle]="null"
        >
            <ng-container *nzDrawerContent>
                <div class="burger">
                    <button 
                        nz-button
                        (click)="blockClicked.emit()"
                    >
                        {{ t('blog') }}
                    </button>

                    <nz-divider></nz-divider>

                    <button 
                        nz-button 
                        (click)="contactClicked.emit()"
                    >
                        {{ t('contact') }}
                    </button>

                    <button 
                        nz-button 
                        ngSkipHydration
                        class="action drawer-lang"
                        (click)="onLangChange()"
                    >
                        <cool-svg-icon 
                            [name]="'c_global'">
                        </cool-svg-icon>

                        <span>{{currentLang()}}</span>
                    </button>

                    <button 
                        nz-button 
                        ngSkipHydration
                        class="action drawer-lang drawer-theme"
                        (click)="onThemeChange()"
                    >
                        <cool-svg-icon 
                            [name]="'c_theme'">
                        </cool-svg-icon>

                        <span>{{t('theme.' + currentTheme() | lowercase)}}</span>
                    </button>
                </div>
            </ng-container>
        </nz-drawer>
    }
</ng-container>