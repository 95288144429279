import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cool-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrl: './main-footer.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainFooterComponent {

}
