import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideNzConfig } from 'ng-zorro-antd/core/config';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { NzMessageService } from 'ng-zorro-antd/message';
import { routes } from 'src/app/app.routes';
import { progressInterceptor } from '../interceptors';
import { LANGUAGE } from 'src/app/constants';
import { LanguageService, StorageService, ThemeService, TranslocoHttpLoader } from '../services';
import { langFactory, themeFactory } from '../utils';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([progressInterceptor]),
      withFetch(),
    ),
    provideRouter(routes),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    provideNzI18n(en_US),
    importProvidersFrom(NzMessageService),
    provideAnimationsAsync(),
    provideNzConfig({
      message: { nzTop: 100 },
    }),
    provideTransloco({
      config: {
        availableLangs: [LANGUAGE.ru, LANGUAGE.en],
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: themeFactory,
      multi: true,
      deps: [ThemeService, StorageService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: langFactory,
      multi: true,
      deps: [LanguageService, StorageService],
    },
  ],
};
