import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';
import { LANGUAGE, THEME } from '../../../../constants';
import { CUSTOM_ICONS_TYPE } from '../../../../typings';

@Component({
  selector: 'cool-main-header',
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainHeaderComponent {
  burgerState = model<boolean>(false);
  currentLang = input<LANGUAGE>();
  currentTheme = input<THEME>();
  isMobile = input<boolean>();
  blockClicked = output<void>();
  contactClicked = output<void>();
  langChange = output<LANGUAGE>();
  themeChange = output<THEME>();

  burgerIcon = computed<CUSTOM_ICONS_TYPE>(() => {
    if (this.burgerState()) {
      return 'c_close';
    }

    return 'c_burger';
  });

  onLangChange(): void {
    if (this.currentLang() === LANGUAGE.en) {
      this.langChange.emit(LANGUAGE.ru);
    } else {
      this.langChange.emit(LANGUAGE.en);
    }
  }

  onThemeChange(): void {
    if (this.currentTheme() === THEME.dark) {
      this.themeChange.emit(THEME.default);
    } else {
      this.themeChange.emit(THEME.dark);
    }
  }

  toggleBurger(): void {
    this.burgerState.set(!this.burgerState());
  }
}
